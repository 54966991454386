<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="9" :sm="24">
            <a-form-item label="地区">
              <a-cascader :value="areaParam" :options="options" @change="areaChange" placeholder="请选择" expandTrigger="hover"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="名称">
              <a-input placeholder="请输入检测中心名称" v-model="listParam.name"/>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="24">
              <span>
                <a-button type="primary" @click="getList">查询</a-button>
                <a-button type="primary" icon="plus" @click="handleEdit(0)" style="margin-left:20px;">新建</a-button>

              </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
            :columns="column"
            :dataSource="centerList"
            :pagination="pagination"
            rowKey="id"
    >
      <template slot="norOperYn" slot-scope="text">
        <template>{{ text == 1 ? '在运行' : '未投用' }}</template>
      </template>
      <template v-for="col in ['centerName', 'startDate', 'adName']" :slot="col" slot-scope="text">
        <template>{{ text }}</template>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <a @click="handleEdit(record.id)">编辑</a>
        <a-divider type="vertical" />
        <a @click="delItem(record.id)">删除</a>
      </template>
    </a-table>
  </a-card>
</template>

<script>
  import { STable, Ellipsis } from '@/components'
  import { getCenterList, deleteCenter, addCenterInfo } from '@/api/basic/center'
  import { getAreaTreeList, getAreaList } from '@/api/basic/common'

  export default {
    name: 'TableList',
    components: {
      STable,
      Ellipsis
    },
    data () {
      return {
        pagination: {
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '20'],
          showTotal: total => `共有 ${total} 条数据`
        },
        // create model
        visible: false,
        confirmLoading: false,
        mdl: null,
        // 高级搜索 展开/关闭
        advanced: false,
        // 查询参数
        listParam: {
          areaId: 0,
          name: ''
        },
        areaParam: [],
        selectedRowKeys: [],
        selectedRows: [],
        // 返回数据
        centerList: [],
        areaInfo: {},
        userAreaId: {
          id: 1
        },
        // 表单数据
        options: [
          {
            value: '1',
            label: '山西省'
          }
        ],
        // table
        column: [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: '8%'
          },
          {
            title: '名称',
            dataIndex: 'centerName',
            key: 'centerName',
            width: '17%',
            scopedSlots: { customRender: 'centerName' }
          },
          {
            title: '建成时间',
            dataIndex: 'startDate',
            key: 'startDate',
            width: '15%',
            scopedSlots: { customRender: 'startDate' }
          },
          {
            title: '是否投用',
            dataIndex: 'norOperYn',
            key: 'norOperYn',
            width: '15%',
            scopedSlots: { customRender: 'norOperYn' }
          },
          {
            title: '地址',
            dataIndex: 'adName',
            key: 'adName',
            width: '25%',
            scopedSlots: { customRender: 'adName' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            scopedSlots: { customRender: 'action' }
          }
        ]
      }
    },
    filters: {},
    created () {
      let area = JSON.parse(localStorage.getItem("areaValue"));
      this.userAreaId.id = area.areaId
      this.listParam.areaId = area.areaId

      //初始化数值
      this.areaParam.push(parseInt(area.areaId))
      this.areaParam.push(parseInt(area.areaId))
      // 请求接口
      this.getAreaTree()

      this.getList()
    },
    computed: {
    },
    methods: {
      areaChange (value, selectedOptions) {
        this.listParam.areaId = value[value.length - 1]
        this.getList()

        this.areaParam = value

        this.areaInfo = selectedOptions[selectedOptions.length-1]
      },
      // 获取列表
      getList () {
        getCenterList(this.listParam).then(response => {
          this.centerList = []

          for (var i = 0;i<response.data.list.length;i++) {
            response.data.list[i].index = i + 1
          }

          this.centerList = response.data.list
        })
      },
      delItem (id) {
        var self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前检测中心？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteCenter({ id: id }).then(response => {
                self.$message.error('删除成功')
                self.getList()
              }).catch((e) => {
                self.$message.error('删除失败')
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      },
      // 接口请求
      // 操作方法
      handleEdit (id) {
        var date = new Date()
        var year = date.getFullYear()
        if (id === 0) {

          // 检查数值
          if (this.areaInfo.level == null || this.areaInfo.level != 3) {
            this.$message.error('请选择县级行政区划')
          } else {

            var formData = {
              adCode: this.areaInfo.code,
              adName: this.areaInfo.label,
              admOrgan: 0,
              areaId: this.areaInfo.value,
              centerName: this.areaInfo.label + '检测中心',
              buiWay: 1,
              cenProvFund: 0,
              cheProPla: 0,
              cheTotAcr: 0,
              cntycInvFund: 0,
              compAcc: 0,
              countyWaterName: 0,
              countyWaterPhone: 0,
              createPeople: 0,
              createTime: 0,
              depName: 0,
              detBas: 0,
              equFund: 0,
              fromRun: 0,
              fromRunOther: 0,
              // id: 1,
              latitude: 0,
              longitude: 0,
              modifierPeople: 0,
              modifierTime: 0,
              norOperYn: 0,
              notDetBas: 0,
              notTarName: 0,
              nowStaffName: 0,
              plaTotPor: 0,
              planYear: '',
              popTotPor: 0,
              provInvFund: 0,
              quaStaffName: 0,
              qualCations: 0,
              reportYear: year,
              runFundYear: 0,
              startDate: 0,
              tarName: 0,
              tarNameOther: 0,
              totInv: 0
            }
            addCenterInfo(formData).then(response => {
              if (response.code === 0) {
                this.$message.info('检测中心添加成功')
                this.$router.push({
                  path: '/base/center/edit',
                  query: {
                    id: response.data
                  }
                })
              } else {
                this.$message.error('该地区检测中心已存在，不可重复添加')
              }
            })
          }
        } else {
          this.$router.push({
            path: '/base/center/edit',
            query: {
              id: id
            }
          })
        }
      },
      getAreaTree () {
        getAreaTreeList(this.userAreaId).then(response => {
          this.options = response.data.list
        })
      }
    }
  }
</script>
