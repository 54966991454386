import http from "@/plugins/https";

const api = {
  page: '/baseQualityCenter/page',
  list: '/baseQualityCenter/list',
  add: '/baseQualityCenter/add',
  update: '/baseQualityCenter/update',
  delete: '/baseQualityCenter/delete',
  info: '/baseQualityCenter/get'
}

export default api

export function getCenterPage (parameter) {
  return http({
    url: api.page,
    method: 'get',
    params: parameter
  })
}

export function getCenterList (parameter) {
  return http({
    url: api.list,
    method: 'get',
    params: parameter
  })
}

export function getCenterInfo (parameter) {
  return http({
    url: api.info,
    method: 'get',
    params: parameter
  })
}

export function addCenterInfo (parameter) {
  return http({
    url: api.add,
    method: 'post',
    data: parameter
  })
}

export function updateCenterInfo (parameter) {
  return http({
    url: api.update,
    method: 'post',
    data: parameter
  })
}

export function deleteCenter (parameter) {
  return http({
    url: api.delete,
    method: 'get',
    params: parameter
  })
}

